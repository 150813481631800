import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import SEO, { seoDataFormat } from "../../shared/seo"

const DevelopmentFeasibilitySEO = () => {
  const { sanityCalculatorDevelopmentFeasibilityPage : { seo }} = useStaticQuery(graphql`
    query {
      sanityCalculatorDevelopmentFeasibilityPage {
        seo {
          ogImage {
            asset {
              url
            }
          }
          pageDescription
          pageKeyWords
          pageTitle
          slug {
            current
          }
        }
      }
    }
  `)

  const seoData = seoDataFormat(seo)

  return (
    <SEO {...seoData} />
  )
}

export default DevelopmentFeasibilitySEO
