import styled from "styled-components"

export default styled.section`
  margin: 8rem 0;

  .container {
    .video-wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`