import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import TabsLink from '../../shared/sub/tabsLink'

const CalculatorsTabs = () => {
  const {
    sanitySharedSection: {
      calculatorTabs: { calculatorTabs },
    },
  } = useStaticQuery(graphql`
    query {
      sanitySharedSection {
        calculatorTabs {
          calculatorTabs {
            _key
            linkText
            url
          }
        }
      }
    }
  `)

  return <TabsLink tabs={calculatorTabs} />
}

export default CalculatorsTabs
