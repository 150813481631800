import styled from "styled-components"

export default styled.section`
  margin: 8rem 0;

  .container {
    display: grid;
    grid-template-columns: 47% 47%;
    grid-gap: 6%;

    @media only screen and (max-width: 980px) {
      grid-template-columns: 100%;
    }

    .right {
      .description {
        color: var(--red);

        p {
          margin-bottom: 1.5rem;
        }

        strong {
          font-family: medium;
        }
      }

      h2 {
        font-family: "medium";
        /* font-size: 14px; */
        font-weight: 900;
        color: var(--red);
      }

      h3.form-title {
        color: var(--red);
        font-family: medium;
        margin: 2rem 0;
        font-size: 2rem;
      }

      form {
        width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
`
