import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import ContentBlock from "../../shared/sub/contentBlock";
import { Image } from "@global";

import TopContentSection from "./topContentStyle";

const TopContent = () => {
  const {
    sanityCalculatorDevelopmentFeasibilityPage: { developmentFeasibilityContent: content },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorDevelopmentFeasibilityPage {
        developmentFeasibilityContent {
          image {
            ...sanityFlightImage
          }
          description {
            _rawContent(resolveReferences: { maxDepth: 5 })
          }
          formTitle
        }
      }
    }
  `);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://lateralpartners.activehosted.com/f/embed.php?id=2";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <TopContentSection>
      <div className="container">
        <Image data={content.image} />

        <div className="right">
          <div className="description">
            <ContentBlock blocks={content.description._rawContent} />
          </div>

          <h3 className="form-title">{content.formTitle}</h3>

          <div className="_form_2"></div>
        </div>
      </div>
    </TopContentSection>
  );
};

export default TopContent;
