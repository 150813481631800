import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import CTA from "../../shared/sub/cta"

const DevelopmentFeasibilityCTAs = () => {
  const {
    sanityCalculatorDevelopmentFeasibilityPage: { CTAs },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorDevelopmentFeasibilityPage {
        CTAs {
          ctas {
            _key
            text {
              _rawContent(resolveReferences: { maxDepth: 5 })
            }
            topBottomSpace
            rightRatio
            reverse
            leftRatio
            imageSize
            image {
              ...sanityFlightImage
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      {CTAs.ctas.map(cta => (
        <CTA {...cta} key={cta._key} />
      ))}
    </div>
  )
}

export default DevelopmentFeasibilityCTAs
