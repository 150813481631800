import React from "react"
import DevelopmentFeasibilitySEO from "../../components/calculators/development-feasibility/developmentFeasibilitySEO"
import CalculatorsTabs from "../../components/calculators/calculatorsTabs"
import DevelopmentFeasibilityCTAs from "../../components/calculators/development-feasibility/ctas"
import TopContent from "../../components/calculators/development-feasibility/topContent"
import Video from "../../components/calculators/development-feasibility/video"
import { useStaticQuery, graphql } from "gatsby"
import { Query } from "@graphql-types"
import { Header } from "@global"
import { colors } from "@util/constants"

const DevelopmentFeasibility = () => {
  const {
    sanityCalculatorDevelopmentFeasibilityPage,
  }: Query = useStaticQuery(graphql`
    query {
      sanityCalculatorDevelopmentFeasibilityPage {
        title
        categories {
          colorList
        }
      }
    }
  `)

  if (sanityCalculatorDevelopmentFeasibilityPage == null) return null
  const { title, categories } = sanityCalculatorDevelopmentFeasibilityPage

  const categoryColor = categories && categories[0] && categories[0].colorList

  const headerData = {
    headerColor: categoryColor ?? colors.maroon,
  }

  return (
    <div className="subpage-container">
      <DevelopmentFeasibilitySEO />
      <Header title={title} headerData={headerData} />
      <CalculatorsTabs />
      <TopContent />
      <Video />
      <DevelopmentFeasibilityCTAs />
    </div>
  )
}

export default DevelopmentFeasibility
