import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import VideoSection from "./videoStyle"

const Video = () => {
  const {
    sanityCalculatorDevelopmentFeasibilityPage: { videoId },
  } = useStaticQuery(graphql`
    {
      sanityCalculatorDevelopmentFeasibilityPage {
        videoId
      }
    }
  `)

  if (videoId === "" || videoId === null) return <></>;

  return (
    <VideoSection>
      <div className="container">
        <div className="video-wrapper">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </VideoSection>
  )
}

export default Video
